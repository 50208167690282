import { DefaultSeo } from "next-seo";
import "../styles/bootstrap.css";
import SEO from "../SEO/next-seo.config";
import "@/styles/index.scss";
import ScrollToTop from "@/components/common/ScrollToTop";
import { CourierProvider } from "@/contexts/providers";
import {StoreProvider} from "../store";
function MyApp({ Component, pageProps }) {
  return (
    <StoreProvider>
      <CourierProvider>
        <DefaultSeo {...SEO} />
        <Component {...pageProps} />
        <ScrollToTop />
      </CourierProvider>
    </StoreProvider>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  const pageProps = Component.getInitialProps
    ? await Component.getInitialProps(ctx)
    : {};
  return { pageProps: pageProps };
};

export default MyApp;
