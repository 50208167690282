export default {
  openGraph: {
    type: 'website',
    locale: 'en',
    url: 'https://synctrack.io/',
    site_name: 'Synctrack',
    images: [
      {
        url: 'https://synctrack.io/images/homepage/thumbnail.png',
        width: 1200,
        height: 627,
        alt: 'Synctrack image thumbnail',
        type: 'image/png',
      },
    ],
  },
};

export const defaultKeyword = [
  {
    name: 'keywords',
    content:
      'Tracking number, tracking info, payment hold, dispute, chargeback, sync tracking, paypal tracking, paypal, stripe, transaction id',
  },
  {
    name: 'description',
    content:
      'Sync tracking to PayPal/Stripe, and process all orders within 1 year. Multiple stores & Unlimited payment account. Verify Transaction ID. Avoid disputes & money on hold',
  },
  {
    name: 'author',
    content: 'Synctrack - Add tracking info',
  },
];

export const defaultLinkTags = [
  {
    rel: 'icon',
    href: '/images/favicon.ico',
  },
];
